<template>
  <v-row class="d-flex justify-center pa-5">
    <v-col>
      <apexchart type="bar" :options="options" :series="series">
      </apexchart>
    </v-col>
    <v-col>
      <v-select
        :items="sel_filter"
        v-model="sel_filter_model"
        label="Filter By"
        @change="handleSelectChange"
        outlined
      ></v-select>
      <v-menu
        v-if="sel_filter_model === 'Monthly' ? true : false"
        ref="date_menu"
        v-model="date_menu"
        :close-on-content-click="false"
        :return-value.sync="datepick"
        transition="slide-x-transition"
        min-width="auto"
        offset-y
      >
        <template v-slot:activator="{ attrs, on }">
          <v-text-field
            outlined
            readonly
            append-icon="mdi-calendar"
            v-model="datepick"
            label="Select Date"
            v-on="on"
            v-bind="attrs"
          ></v-text-field>
        </template>
        <v-date-picker
          type="month"
          @change="$refs.date_menu.save(datepick)"
          v-model="datepick"
          min="2000-01"
          :max="max_date"
          no-title
        >
          <v-spacer></v-spacer>
          <v-btn text large @click="date_menu = false"> cancel </v-btn>
          <v-btn
            text
            large
            color="primary"
            @click="$refs.date_menu.save(datepick)"
          >
            ok
          </v-btn>
        </v-date-picker>
      </v-menu>
      <v-menu
        v-if="sel_filter_model === 'Daily' ? true : false"
        ref="date_menu"
        v-model="date_menu"
        :close-on-content-click="false"
        :return-value.sync="datepick"
        transition="slide-x-transition"
        min-width="auto"
        offset-y
      >
        <template v-slot:activator="{ attrs, on }">
          <v-text-field
            outlined
            readonly
            append-icon="mdi-calendar"
            v-model="datepick"
            label="Select Date"
            v-on="on"
            v-bind="attrs"
          ></v-text-field>
        </template>
        <v-date-picker
          @change="$refs.date_menu.save(datepick)"
          v-model="datepick"
          no-title
        >
          <v-spacer></v-spacer>
          <v-btn text large @click="date_menu = false"> cancel </v-btn>
          <v-btn
            text
            large
            color="primary"
            @click="$refs.date_menu.save(datepick)"
          >
            ok
          </v-btn>
        </v-date-picker>
      </v-menu>
      <v-btn
        large
        depressed
        width="150"
        :disabled="datepick === null"
        color="brown accent-1"
        v-if="sel_filter_model !== null"
        @click="filterDate"
      >
        <span class="white--text"> apply </span>
        <v-icon class="white--text" right>mdi-format-list-checks</v-icon>
      </v-btn>
      <v-btn
        text
        large
        width="150"
        v-if="sel_filter_model !== null"
        class="ml-3"
        @click="
          () => {
            sel_filter_model = null;
            datepick = null;
            this.getCount();
          }
        "
      >
        reset
        <v-icon right>mdi-reload</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      max_date: `${new Date().getFullYear().toString()}-12}`,
      sel_filter_model: null,
      sel_filter: ["Monthly", "Daily"],
      datepick: null,
      date_menu: false,
      options: {
        chart: {
          type: "bar",
          id: "interaction-chart",
        },
        plotOptions: {
          bar: {
            barHeight: "100%",
            distributed: true,
            // horizontal: true,
            dataLabels: {
              position: "bottom",
            },
          },
        },
        xaxis: {
          labels: {
            show: false,
          },
          categories: [
            "WHATSAPP",
            "TOKOPEDIA",
            "BUKALAPAK",
            "LAZADA",
            "SHOPEE",
            "FACEBOOK",
            "INSTAGRAM",
            "TIKTOK",
          ],
        },
        colors: [
          "#51CA5F",
          "#3CAF41",
          "#E31D53",
          "#000083",
          "#EE4E2C",
          "#5EA3EF",
          "#CF4DF3",
          "#D3483E",
        ],
        title: {
          text: "Chart Interaksi Potential Buyer",
          align: "center",
          floating: true,
        },
      },
      series: [],
      dataLabels: {
        enabled: true,
        style: {
          colors: ["#a2ed00"],
        },
      },
    };
  },
  mounted() {
    this.getCount();
  },
  methods: {
    async getCount() {
      var tmp = [];
      await axios
        .get(`${process.env.VUE_APP_KB_API_URL}/api/stats/interactions/count`)
        .then((response) => {
          for (let key in response.data) {
            tmp.push(response.data[key]);
          }
        });
      (this.series = [
        {
          name: "Jumlah Interaksi",
          data: tmp,
        },
      ]),
        console.log(tmp);
    },
    handleSelectChange() {
      let date = new Date();
      this.datepick = `${date.getFullYear().toString()}-${date
        .getMonth()
        .toString()}-${date.getDate().toString()}`;
    },
    async filterDate() {
      var tmp = [];
      if (this.sel_filter_model === "Monthly") {

        let response = await axios.get(`${process.env.VUE_APP_KB_API_URL}/api/stats/interactions/count/month/${this.datepick}`)
        
        
        for (let key in response.data) {
          tmp.push(response.data[key]);
        }
        this.series = [
          {
            name: "Jumlah Interaksi",
            data: tmp,
          },
        ];
      } else {
        let response = await axios.get(
          `${process.env.VUE_APP_KB_API_URL}/api/stats/interactions/count/day/${this.datepick}`
        );
        for (let key in response.data) {
          tmp.push(response.data[key]);
        }
        this.series = [
          {
            name: "Jumlah Interaksi",
            data: tmp,
          },
        ];
      }
    },
  },
};
</script>