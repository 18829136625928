<template>
  <v-row>
    <v-col>
      <v-card outlined>
        <v-card-title>
          <v-col>
            <v-row>
              <v-col>
                <v-autocomplete
                  outlined
                  label="Filter By"
                  :items="select.item_filter_by"
                  v-model="select.vm"
                ></v-autocomplete>
              </v-col>
              <v-col>
                <v-menu
                  ref="date_menu"
                  v-model="datepick.vm_menu"
                  :close-on-content-click="false"
                  :return-value.sync="datepick.vm_date"
                  transition="slide-x-transition"
                  min-width="auto"
                  offset-y
                >
                  <template v-slot:activator="{ attrs, on }">
                    <v-text-field
                      v-on="on"
                      v-bind="attrs"
                      append-icon="mdi-calendar"
                      label="Select Date"
                      :disabled="select.vm !== null ? false : true"
                      v-model="datepick.vm_date"
                      outlined
                      readonly
                    ></v-text-field>
                  </template>

                  <v-date-picker
                    @change="$refs.date_menu.save(datepick.vm_date)"
                    v-model="datepick.vm_date"
                    v-if="select.vm === 'Daily' ? true : false"
                    no-title
                  ></v-date-picker>
                  <v-date-picker
                    @change="$refs.date_menu.save(datepick.vm_date)"
                    v-model="datepick.vm_date"
                    v-if="select.vm === 'Monthly' ? true : false"
                    type="month"
                    no-title
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-btn
              text
              large
              color="grey"
              class="float-right ml-3"
              @click="handleReset"
            >
              reset
              <v-icon>mdi-reload</v-icon>
            </v-btn>
            <v-btn
              depressed
              large
              class="float-right"
              color="brown accent-2"
              @click="filterRegencyData"
              :disabled="datepick.vm_date === null ? true : false"
            >
              <span class="white--text">apply</span>
              <v-icon right class="white--text">mdi-content-save</v-icon>
            </v-btn>
          </v-col>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="table.headers"
            :items="table.items"
            :loading="table.loading"
          ></v-data-table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      datepick: {
        vm_menu: false,
        vm_date: null,
      },
      table: {
        headers: [
          { text: "ID", value: "id" },
          { text: "City ID", value: "city_id" },
          { text: "City", value: "kota" },
          { text: "Province", value: "provinsi" },
          { text: "Interaction Count", value: "total_count", align: 'center' },
        ],
        items: [],
        loading: false,
      },
      select: {
        item_filter_by: ["Daily", "Monthly"],
        vm: null,
      },
    };
  },
  methods: {
    async getRegencyData() {
      this.table.loading = true;
      let response = await axios.get(
        `${process.env.VUE_APP_KB_API_URL}/api/stats/interactions/count/regency`
      );

      this.table.items = response.data;
      this.table.loading = false;
    },
    async filterRegencyData() {
      let select_vm = this.select.vm;
      let vm_date = this.datepick.vm_date;
      let self = this;
      if (select_vm === "Daily") {
        this.table.loading = true;
        await axios
          .get(
            `${process.env.VUE_APP_KB_API_URL}/api/stats/interactions/count/regency/daily/${vm_date}`
          )
          .then((response) => {
            console.log(response.status);
            self.table.items = response.data;
            self.table.loading = false;
          })
          .catch((err) => {
            console.log(err);
            self.table.loading = false;
          });
        console.log("filter data by day");
      } else if (select_vm === "Monthly") {
        this.table.loading = true;
        await axios
          .get(
            `${process.env.VUE_APP_KB_API_URL}/api/stats/interactions/count/regency/monthly/${vm_date}`
          )
          .then((response) => {
            self.table.loading = false;
            console.log(response.status);
            self.table.items = response.data;
          })
          .catch((err) => {
            self.table.loading = false;
            console.log(err);
          });
        console.log("filter data by month");
      } else {
        console.log("Choose Range");
      }
    },
    handleReset() {
      this.getRegencyData();
      this.datepick.vm_date = null;
      this.select.vm = null;
    },
  },
  mounted() {
    this.getRegencyData();
  },
};
</script>