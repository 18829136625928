<template>
  <v-row no-gutter class="pa-10">
    <v-col>
      <v-row>
        <v-col>
          <v-card rounded="lg">
            <v-card-title>
              <v-col>
                <h3>Chart Jumlah Reseller</h3>
              </v-col>
            </v-card-title>
            <v-card-text>
              <div class="d-flex justify-center">
                <bar-chart></bar-chart>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-card>
            <v-card-text>
              <interaction-chart></interaction-chart>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-card>
            <v-card-text>
              <br />
              <v-row>
                <v-col>
                  <regency-table></regency-table>
                </v-col>
                <v-col>
                  <province-table></province-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import BarChart from "../../components/BarChart.vue";
import InteractionChart from "@/components/InteractionChart.vue";
import RegencyTable from "@/components/RegencyTable.vue";
import axios from "axios";
import ProvinceTable from '../../components/ProvinceTable.vue';

export default {
  data() {
    return {
      table: {
        headers: [
          { text: "ID", value: "reseller_id" },
          { text: "WhatsApp", value: "whatsapp" },
          { text: "Tokopedia", value: "tokopedia" },
          { text: "Bukalapak", value: "bukalapak" },
          { text: "Lazada", value: "lazada" },
          { text: "Shopee", value: "shopee" },
          { text: "Facebook", value: "facebook" },
          { text: "Instagram", value: "instagram" },
          { text: "TikTok", value: "tiktok" },
        ],
        items: [],
      },
      stats: null,
    };
  },
  components: {
    BarChart,
    RegencyTable,
    InteractionChart,
    ProvinceTable,
  },
  methods: {
    async getOrderStats() {
      var tmp = [];
      await axios
        .get(`${process.env.VUE_APP_KB_API_URL}/api/stats/order`)
        .then((response) => {
          console.log(response.status);
          tmp = response.data;
        })
        .catch((err) => {
          console.log(err);
        });
      this.table.items = tmp;
    },
    async getCount() {
      var tmp = {};
      await axios
        .get(`${process.env.VUE_APP_KB_API_URL}/api/stats/order/count`)
        .then((response) => {
          tmp = response.data;
        })
        .catch((err) => {
          console.log(err);
        });
      this.stats = tmp;
    },
  },
  async created() {
    await this.getOrderStats();
  },
  mounted() {
    this.getCount();
  },
};
</script>